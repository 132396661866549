@import "header-component-hidden";
body {
    font-family: 'Montserrat', sans-serif;
    width: 100%;
    font-size: 14px;
    color: #909090;

}

a {
    color: #ffa016;
    transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    &:hover {
        text-decoration: none;
    }
}

img, svg {
    max-width: 100%;
    height: auto;
}
legend {
    font-size: 18px;
    padding: 7px 0px
}

label {
    font-size: 14px;
    font-weight: normal;
}

select.form-control, textarea.form-control, input[type="text"].form-control, input[type="password"].form-control, input[type="datetime"].form-control, input[type="datetime-local"].form-control, input[type="date"].form-control, input[type="month"].form-control, input[type="time"].form-control, input[type="week"].form-control, input[type="number"].form-control, input[type="email"].form-control, input[type="url"].form-control, input[type="search"].form-control, input[type="tel"].form-control, input[type="color"].form-control {
    font-size: 14px;
}

.input-group {
    input,
    select,
    .dropdown-menu,
    .popover {
        font-size: 14px;
    }

    .input-group-addon {
        font-size: 14px;
        height: 30px;
    }
}


/* Fix some bootstrap issues */
span.hidden-xs, span.hidden-sm, span.hidden-md, span.hidden-lg {
    display: inline;
}

.nav-tabs {
    margin-bottom: 15px;
}

div.required .control-label:before {
    content: '* ';
    color: #F00;
    font-weight: bold;
}



/* top */
#top {
    background-color: #EEEEEE;
    border-bottom: 1px solid #e2e2e2;
    padding: 4px 0px 3px 0;
    margin: 0 0 20px 0;
    min-height: 40px;
    .container {
        padding: 0 20px;
    }

     #form-currency .currency-select,
     #form-language .language-select {
        text-align: left;
         &:hover {
             text-shadow: none;
             color: #ffffff;
             background-color: #ffa016;
             background-image: linear-gradient(to bottom, #ffa016, #ffa016);
             background-repeat: repeat-x;
         }
    }



    .btn-link {
        color: #888;
        text-shadow: 0 1px 0 #FFF;
        text-decoration: none;
        &:hover{
            color: #444;
        }
        strong {
            font-size: 14px;
            line-height: 14px;
        }

    }


}
#top-links {
    padding-top: 6px;
     li {
        color: #888;
        text-shadow: 0 1px 0 #FFF;
        text-decoration: none;
    }
    a {
        color: #888;
        text-shadow: 0 1px 0 #FFF;
        text-decoration: none;
        &:hover {
            color: #444;
        }
        & + a {
            margin-left: 15px;
        }
    }
    .dropdown-menu a {
        text-shadow: none;
        &:hover {
            color: #FFF;
        }
    }
}

/* logo */
#logo {
    margin: 0 0 10px 0;
        position: absolute;
        z-index: 101;
        top: -12px;
}

/* search */
#search {
    margin-bottom: 10px;
    .input-lg {
        height: 40px;
        line-height: 20px;
        padding: 0 10px;
    }

    .btn-lg {
        font-size: 15px;
        line-height: 18px;
        padding: 10px 35px;
        text-shadow: 0 1px 0 #FFF;
    }
}



/* cart */
#cart {
    margin-bottom: 10px;
    > .btn {
        font-size: 12px;
        line-height: 18px;
        color: #FFF;
    }
    &.open > .btn {
        background-image: none;
        background-color: #FFFFFF;
        border: 1px solid #E6E6E6;
        color: #666;
        box-shadow: none;
        text-shadow: none;
        &:hover {
            color: #444;
        }
    }
     .dropdown-menu {
        background: #eee;
        z-index: 1001;
        min-width: 100%;
        @media (max-width: 478px) {
            width: 100%;
        }
        table {
            margin-bottom: 10px;
        }
        li {
            & > div {
                min-width: 427px;
                padding: 0 10px;
                @media (max-width: 478px) {
                    min-width: 100%;
                }
            }
            p {
                margin: 20px 0;
            }
        }
    }
}


/* menu */
#menu {
    background-color: #ffa016;
    background-image: linear-gradient(to bottom, #ffa016, #ffa016);
    background-repeat: repeat-x;
    border-color: #ffa016 #ffa016 #ffa016;
    min-height: 40px;
    @media (max-width: 767px) {
        border-radius: 4px;
    }
    .nav > li{
        &.open > a {
            background-color: rgba(0, 0, 0, 0.1);
        }
        & > a {
            color: #fff;
            text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
            padding: 10px 15px 10px 15px;
            min-height: 15px;
            background-color: transparent;
            &:hover{
                background-color: rgba(0, 0, 0, 0.1);
            }
        }
    }
    .dropdown-menu {
        padding-bottom: 0;
        @media (max-width: 767px) {
            a:hover,
            ul li a:hover {
                background: rgba(0, 0, 0, 0.1);
            }
        }
    }

    .dropdown-inner {
        display: table;
        @media (max-width: 767px) {
            display: block;
        }
            ul {
            display: table-cell;
        }
        li a:hover {
            color: #FFFFFF;
        }
        a {
            min-width: 160px;
            display: block;
            padding: 3px 20px;
            clear: both;
            line-height: 20px;
            color: #333333;
            font-size: 12px;
            @media (max-width: 767px) {
                width: 100%;
                color: #fff;
            }
        }
    }
    .see-all {
        display: block;
        margin-top: 0.5em;
        border-top: 1px solid #DDD;
        padding: 3px 20px;
        -webkit-border-radius: 0 0 4px 4px;
        -moz-border-radius: 0 0 4px 4px;
        border-radius: 0 0 3px 3px;
        font-size: 12px;
        &:hover,
        &:focus {
            text-decoration: none;
            color: #ffffff;
            background-color: #ffa016;
            background-image: linear-gradient(to bottom, #ffa016, #ffa016);
            background-repeat: repeat-x;
        }
        @media (max-width: 767px) {
            margin-top: 0;
            border: none;
            border-radius: 0;
            color: #fff;
        }
    }

    #category {
        float: left;
        padding-left: 15px;
        font-size: 16px;
        font-weight: 700;
        line-height: 40px;
        color: #fff;
        text-shadow: 0 1px 0 rgba(0, 0, 0, 0.2);
    }

    .btn-navbar {
        font-size: 15px;
        font-stretch: expanded;
        color: #FFF;
        padding: 2px 18px;
        float: right;
        background-color: #ffa016;
        background-image: linear-gradient(to bottom, #ffa016, #ffa016);
        background-repeat: repeat-x;
        border-color: #ffa016 #ffa016 #ffa016;

        &:hover,
        &:focus,
        &:active,
        &.disabled,
        &[disabled] {
            color: #ffffff;
            background-color: #ffa016;
        }
    }

    @media (min-width: 768px) {
        .dropdown:hover .dropdown-menu {
            display: block;
        }
    }
    @media (max-width: 767px) {
        div.dropdown-inner > ul.list-unstyled {
            display: block;
        }
        div.dropdown-menu {
            margin-left: 0 !important;
            padding-bottom: 10px;
            background-color: rgba(0, 0, 0, 0.1);
        }
    }
}
/* content */
#content {
    min-height: 600px;
}


/* alert */
.alert {
    padding: 8px 14px 8px 14px;
}

/* breadcrumb */
.breadcrumb {
    margin: 0 0 0px 0;
    padding: 0px 0;
    border: 0px solid #ddd;
    background: none;
    margin-bottom: 20px;
    margin-top: 30px;
    font-size: 12px;
     i {
        font-size: 14px;
    }
    & > li {
        padding: 0 0px;
        & + li:before {
            content: '/';
            padding: 10px;
        }
        &:after {
            content: '';
        }
    }

}



.pagination {
    margin: 0;
}

/* buttons */
.buttons {
    margin: 1em 0;
}

.btn {
    padding: 7.5px 12px;
    font-size: 12px;
    border: 1px solid #cccccc;
    border-radius: 4px;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .2), 0 1px 2px rgba(0, 0, 0, .05);
}
.btn-xs {
    font-size: 9px;
}
.btn-sm {
    font-size: 10.2px;
}
.btn-lg {
    padding: 10px 16px;
    font-size: 15px;
}
.btn-group {
    & > .btn-xs {
        font-size: 9px;
    }
     & > .btn-sm {
        font-size: 10.2px;
    }

     & > .btn-lg {
        font-size: 15px;
    }
}
.btn-default {
    color: #777;
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
    background-color: #e7e7e7;
    background-image: linear-gradient(to bottom, #eeeeee, #dddddd);
    background-repeat: repeat-x;
    border-color: #dddddd #dddddd #b3b3b3 #b7b7b7;
}

.btn-primary {
    color: #ffffff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    background-color: #ffa016;
    background-image: linear-gradient(to bottom, #ffa016, #ffa016);
    background-repeat: repeat-x;
    border-color: #ffa016 #ffa016 #ffa016;
    &:hover,
    &:active,
    &.active,
    &.disabled,
    &[disabled] {
        background-color: #ffa016;
        background-position: 0 -15px;
    }
}

.btn-warning {
    color: #ffffff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    background-color: #faa732;
    background-image: linear-gradient(to bottom, #fbb450, #f89406);
    background-repeat: repeat-x;
    border-color: #f89406 #f89406 #ad6704;
    &:hover,
    &:active,
    &.active,
    &.disabled,
    &[disabled] {
        box-shadow: inset 0 1000px 0 rgba(0, 0, 0, 0.1);
    }
}

.btn-danger {
    color: #ffffff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    background-color: #da4f49;
    background-image: linear-gradient(to bottom, #ee5f5b, #bd362f);
    background-repeat: repeat-x;
    border-color: #bd362f #bd362f #802420;
    &:hover,
    &:active,
    &.active,
    &.disabled,
    &[disabled] {
        box-shadow: inset 0 1000px 0 rgba(0, 0, 0, 0.1);
    }
}

.btn-success {
    color: #ffffff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    background-color: #5bb75b;
    background-image: linear-gradient(to bottom, #62c462, #51a351);
    background-repeat: repeat-x;
    border-color: #51a351 #51a351 #387038;
    &:hover,
    &:active,
    &.active,
    &.disabled,
    &[disabled] {
        box-shadow: inset 0 1000px 0 rgba(0, 0, 0, 0.1);
    }
}

.btn-info {
    color: #ffffff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    background-color: #df5c39;
    background-image: linear-gradient(to bottom, #e06342, #dc512c);
    background-repeat: repeat-x;
    border-color: #dc512c #dc512c #a2371a;
    &:hover,
    &:active,
    &.active,
    &.disabled,
    &[disabled] {
        background-image: none;
        background-color: #df5c39;
    }
}

.btn-link {
    border-color: rgba(0, 0, 0, 0);
    cursor: pointer;
    color: #ffa016;
    border-radius: 0;
    background-color: rgba(0, 0, 0, 0);
    background-image: none;
    box-shadow: none;

    &:active,
    &[disabled] {
        background-color: rgba(0, 0, 0, 0);
        background-image: none;
        box-shadow: none;
    }
}

.btn-inverse {
    color: #ffffff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    background-color: #363636;
    background-image: linear-gradient(to bottom, #444444, #222222);
    background-repeat: repeat-x;
    border-color: #222222 #222222 #000000;
    &:hover,
    &:active,
    &.active,
    &.disabled,
    &[disabled] {
        background-color: #222222;
        background-image: linear-gradient(to bottom, #333333, #111111);
    }
}

/* list group */
.list-group a {
    border: 1px solid #DDDDDD;
    color: #888888;
    padding: 8px 12px;
    &.active,
    &.active:hover,
    &:hover {
        color: #444444;
        background: #eeeeee;
        border: 1px solid #DDDDDD;
        text-shadow: 0 1px 0 #FFF;
    }
}



/* carousel */
.carousel-caption {
    color: #FFFFFF;
    text-shadow: 0 1px 0 #000000;
}

.carousel-control{
    .icon-prev:before {
        content: '\f053';
        font-family: FontAwesome;
    }

    .icon-next:before {
        content: '\f054';
        font-family: FontAwesome;
    }
}

/* product list */
.product-thumb {
    border: 1px solid #ddd;
    margin-bottom: 20px;
    overflow: auto;
    .image {
        text-align: center;
        a {
            display: block;
            &:hover {
                opacity: 0.8;
            }
        }
        img {
            margin-left: auto;
            margin-right: auto;
        }
    }
    h4 {
        font-weight: bold;
    }

    .caption {
        padding: 0 20px;
        min-height: 180px;
    }
     .rating {
        padding-bottom: 10px;
    }
    .price {
        color: #444;
    }
      .price-new {
        font-weight: 600;
    }

     .price-old {
        color: #999;
        text-decoration: line-through;
        margin-left: 10px;
    }

     .price-tax {
        color: #999;
        font-size: 12px;
        display: block;
    }

     .button-group {
        border-top: 1px solid #ddd;
        background-color: #eee;
        overflow: auto;
         button {
             width: 60%;
             border: none;
             display: inline-block;
             float: left;
             background-color: #eee;
             color: #888;
             line-height: 38px;
             font-weight: bold;
             text-align: center;
             text-transform: uppercase;
             @media (max-width: 1200px) {

                 width: 33.33%;

             }
             @media (max-width: 767px) {

                 width: 33.33%;
             }
             & + button {
                 width: 20%;
                 border-left: 1px solid #ddd;
                 @media (max-width: 1200px) {

                     width: 33.33%;
                 }
                 @media (max-width: 767px) {

                     width: 33.33%;
                 }
             }
             &:hover {
                 color: #444;
                 background-color: #ddd;
                 text-decoration: none;
                 cursor: pointer;
             }

         }
    }
}

.product-grid .product-thumb {
    .image {
        float: none;
        @media (min-width: 767px) {
            float: left;
            padding: 0 15px;
        }
    }
    @media (max-width: 1200px) {
         .caption {
            min-height: 210px;
            padding: 0 10px;
        }
    }

    @media (max-width: 767px) {

         .caption {
            min-height: 0;
        }
    }
}
.product-list .product-thumb {
    .caption {
        margin-left: 230px;
        @media (max-width: 767px) {
            min-height: 0;
            margin-left: 0;
            padding: 0 10px;
        }
    }
     .button-group {
        border-left: 1px solid #ddd;
        @media (max-width: 768px) {
            border-left: none;

        }
    }
}
.rating {
    .fa-stack {
        font-size: 8px;
    }
    .fa-star-o {
        color: #999;
        font-size: 15px;
    }
    .fa-star {
        color: #FC0;
        font-size: 15px;
    }
    .fa-star + .fa-star-o {
        color: #E69500;
    }
}
h2.price {
    margin: 0;
}


.thumbnails {
    overflow: auto;
    clear: both;
    list-style: none;
    padding: 0;
    margin: 0;
    margin-left: -20px;
     & > li {
        margin-left: 20px;
    }
    & > img {
        width: 100%;
    }
     .image-additional {
        float: left;
        margin-left: 20px;
    }
}


.image-additional {
    max-width: 78px;

    a {
        margin-bottom: 20px;
        padding: 5px;
        display: block;
        border: 1px solid #ddd;
    }

}

body {
    #owl-thumb .item img {
        max-width: 100%;
    }

    #owl-additional .item {
        border: 1px solid transparent;
        margin: 15px 0;
        border-radius: 4px;

        &.active {
            border-color: #ffa016;
            cursor: default;
            box-shadow: 0 0 8px rgba(255, 160, 22, 0.5);
        }

        img {
            max-width: 100%;
        }
    }
}

@media (min-width: 1200px) {
    #content .col-lg-2:nth-child(6n+1),
    #content .col-lg-2:nth-child(6n+1),
    #content .col-lg-3:nth-child(4n+1),
    #content .col-lg-4:nth-child(3n+1),
    #content .col-lg-6:nth-child(2n+1) {
        clear: left;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    #content .col-md-2:nth-child(6n+1),
    #content .col-md-2:nth-child(6n+1),
    #content .col-md-3:nth-child(4n+1),
    #content .col-md-4:nth-child(3n+1),
    #content .col-md-6:nth-child(2n+1) {
        clear: left;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    #content .col-sm-2:nth-child(6n+1),
    #content .col-sm-2:nth-child(6n+1),
    #content .col-sm-3:nth-child(4n+1),
    #content .col-sm-4:nth-child(3n+1),
    #content .col-sm-6:nth-child(2n+1) {
        clear: left;
    }
}

/* fixed colum left + content + right*/
@media (min-width: 768px) {
    #column-left .product-layout .col-md-3 {
        width: 100%;
    }

    #column-left + #content .product-layout .col-md-3 {
        width: 50%;
    }

    #column-left + #content + #column-right .product-layout .col-md-3 {
        width: 100%;
    }

    #content + #column-right .product-layout .col-md-3 {
        width: 100%;
    }
}
/* fixed product layouts used in left and right columns */
#column-left .product-layout,
#column-right .product-layout {
    width: 100%;
}

/* fixed mobile cart quantity input */
.input-group .form-control[name^=quantity] {
    min-width: 50px;
}

/************************rtsasuk**************************/

.nopad {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.megamenu-thumb {
    display: none;
}


.common-home .header{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 2222;
}
.header {
    margin-top: 45px;
     height: 66px;
    background: #fbfbfb;
    box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.3);
    z-index: 1001;
    position: relative;
    @media (min-width: 768px) and (max-width: 991px) {
         .container-fluid{
            padding: 0;
        }
    }

    @media  (max-width: 991px){
        height: 66px;
        margin-top: 25px;
        .container {
            height: 100%;
            display: flex;
            justify-content: center;
            max-width: 100%;
        }
        .row {
            width: 100%;
            display: flex;
            justify-content: space-around;
        }
        &__logo{
            width: calc(100% - 55px);
            padding: 0;
        }
        &__menu-box{
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            width: auto;
            padding: 0;
        }
        &__modal{
            display: flex;
        }
    }
    &__burger {
        display: none;
        width: 36px;
        height: 24px;
        //right:  20px;
        position: relative;
        transform: rotate(0deg);
        transition: .5s ease-in-out;
        cursor: pointer;
        z-index: 4;
        margin: 0 0 0 auto;
        @media  (max-width: 991px){
            display: flex;
        }
        span {
            display: block;
            position: absolute;
            height: 3px;
            width: 100%;
            background:#ffa016;

            opacity: 1;
            left: 0;
            transform: rotate(0deg);
            transition: .25s ease-in-out;

            &:nth-child(1) {
                top: 0px;
            }

            &:nth-child(2),
            &:nth-child(3) {
                top: 9px;
            }

            &:nth-child(4) {
                top: 18px;
            }
        }
        &.burger-active{
            span {
                &:nth-child(1),
                &:nth-child(4) {
                    top: 9px;
                    width: 0%;
                    left: 50%;
                }

                &:nth-child(2) {
                    transform: rotate(135deg);
                }

                &:nth-child(3) {
                    transform: rotate(45deg);
                }

            }
        }
    }
}


.tel {
    font-size: 22px;
    color: #ffa016;
    font-weight: bold;
    display: block;
    line-height: 67px;
    float: right;
    @media (max-width: 767px) {
        text-align: center;
        float: none;
    }
    span {
        color: #909090;
        font-weight: 400 !important;
    }
    @media (min-width: 768px) and (max-width: 991px) {
            text-align: center;
            float: none;
    }

}


.det {
    display: block;
    line-height: 1 !important;
    background: #282828;
    font-size: 14px !important;
    padding: 12px 40px !important;
    text-transform: uppercase;
    font-weight: 500;
    text-align: center;
    &:hover {
        background: #282828;
    }
}

.sc-catalog {
    padding-top: 50px;
}
.sc-heading {
    font-size: 36px;
    color: #282828;
    font-weight: 500;
    text-align: center;
    text-transform: uppercase;
    display: block;
    margin-bottom: 35px;
    @media (max-width: 767px) {
        font-size: 26px;
    }

}

.dropdown-menu {
    background: rgba(251, 251, 251, 0.9);
    /* Gradent to all drop down menus */
     li > a:hover {
        text-decoration: none;
        color: #ffffff;
        background-color: #ffa016;
        background-image: linear-gradient(to bottom, #ffa016, #ffa016);
        background-repeat: repeat-x;
    }
}

#content h1 {
    margin-top: 0px !important;
    font-size: 26px;
    color: #ffa016;
    text-transform: uppercase;
    display: block;
    margin-bottom: 20px;
    @media only screen and (max-width: 540px){
        font-size: 22px;
    }
}

.input-group-addon {
    background: none !important;
    border: 0px solid #333333 !important;
}

.box-heading {
    font-size: 22px;
    color: #ffffff !important;
    font-weight: bold;
    text-transform: uppercase;
    background: #ffa016 !important;
}

.bf-arrow {
    display: none !important;
}

.fon_tov {
    background: #fbfbfb;
    margin-bottom: 30px;
    padding: 15px;
    a {
        font-weight: 500;
        font-size: 14px;
        text-transform: uppercase;
        color: #282828;
        &:hover {
            color: #ffa016;
        }
    }
}

.product {
    &__att {
        display: flex;
        flex-direction: column;
        &__item{
            display: flex;
        }
        &__name {
            width: 35%;
            padding-right: 15px;
        }
        &__val {
            width: 65%;
            font-weight: 700;
        }
    }
}
.image1 {
    margin-bottom: 15px;
    img{
        margin: auto;
    }
}

//#val_har {
//    font-weight: bold;
//}
.desc img {
    display: block !important;
    max-width: 100% !important;
    height: auto !important;
}
/* footer */
.goto-top {
    position: fixed;
    right: 30px;
    bottom: 30px;
    height: 46px;
    width: 46px;
    cursor: pointer;
    display: block;
    border-radius: 50px;
    box-shadow: inset  0 0 0 2px rgba(0,0,0,0.1);
    z-index: 10000;
    opacity: 0;
    visibility: hidden;
    transform: translateY(15px);
    -webkit-transition: all 200ms linear;
    transition: all 200ms linear;
    &.active-progress {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
    }
    &:hover{
        .goto-top__arrow{
            &:before{
                background: #000;
            }
            &:after{
                border-color: #000;
            }
        }
    }
    &__circle path {
        stroke: #282828;
        stroke-width: 4;
        box-sizing:border-box;
        -webkit-transition: all 200ms linear;
        transition: all 200ms linear;
        fill: none;
    }
    &__arrow {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        &:before {
            content: "";
            position: absolute;
            top: 16px;
            width: 2px;
            height: 14px;
            background: #282828;
            left: 50%;
            transform: translateX(-50%);
        }
        &:after {
            position: absolute;
            content: "";
            left: 50%;
            top: 16px;
            height: 8px;
            width: 8px;
            border-top: 2px solid  #282828;
            border-right: 2px solid #282828;
            transform: translateX(-50%)  rotate(-45deg);
            cursor: pointer;
            display: block;
            z-index: 1;
            -webkit-transition: all .2s linear;
            -o-transition: all .2s linear;
            transition: all .2s linear;
        }

    }
}
footer {
    background-color: #ffa016;
    padding: 20px 0;
    margin-top: 50px;
    .row{
        display: flex;
        align-items: center;
    }

}
.telf {
    font-size: 20px;
    color: #282828;
    font-weight: 700;
    @media (max-width: 767px) {
        text-align: center;
    }
    span {
        font-size: 20px;
        color: #282828;
        font-weight: 500;
        line-height: 100px;
    }

}

.soc {
    width: 150px;
    display: flex;
    list-style: none;
    margin: 0px auto;
    padding: 0px;
    /*line-height: 100px;*/
     a {
        color: #282828;
        font-size: 32px;
        padding: 10px;
        /*margin-left: 10px;*/
        /*margin-right: 10px;*/
        transition: all 0.3s ease-in-out;
        &:hover {
            color: #fff;
        }
    }
}
.copy {
    color: #282828;
    font-size: 14px;
    font-weight: 500;
    line-height: 50px;
    @media (min-width: 768px) and (max-width: 991px) {
        text-align: center;
    }
    @media (max-width: 767px) {
            text-align: center;
    }

}

@media (max-width: 767px) {
    .showcase .item-info a {
        font-size: 14px !important;
    }
    .navbar-ex1-collapse {
        /*margin-top: 10px;*/
        position: absolute;
        z-index: 2000;
        background: #fbfbfb;
        top: 115%;
        left: 0;
        right: 0;
        width: 100%;
        /*left: -100px;*/
        border-left: 2px solid #ffa016;
        border-right: 2px solid #ffa016;
        border-bottom: 2px solid #ffa016;
        padding: 0;
    }
    .navbar-ex1-collapse .navbar-nav{
        margin: 7.5px 0;
    }
    .tp-caption.bignumbers_whitefdf {
        font-size: 22px !important;

    }

    .tp-caption.black {
        font-size: 16px !important;
        line-height: 1.4 !important;
    }

    .rev_slider_wrapper {
        height: 500px !important;
    }

    .rev_slider {
        height: 100% !important;
    }
}
/************************end rtsasuk**************************/