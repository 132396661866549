.body-fixed {
  overflow: hidden;
}
.header{
  &__modal {
    //padding-top: 100px;
    display: none;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    padding-top: 90px;
    width: calc(100% - (60px - 15px));
    height: 100%;
    z-index: 2221;
    transform: translateX(-100%);
    transition: transform 0.4s ease-in-out;
    min-width: 140px;
    max-width: 400px;
    background: #fff;
    @media  (max-width: 991px){
      display: flex;
      flex-direction: column;
    }

    &.active {
      transform: translateX(0) !important;
    }
    span {
      font-family: inherit;
    }

    .container {
      display: flex;
      flex-direction: column;
      overflow-x: auto;
      width: 100%;
    }

    .modal {
      &__content {
         width: 100%;
        padding-top: 30px;
        @media (min-width: 768px) {
          .navbar-nav {
            float: none;
            & > li {
              float: none;
            }
          }
        }
        @media (min-width: 768px) and (max-width: 991px) {
          .navbar-nav .open .dropdown-menu {
            position: static;
            float: none;
            width: auto;
            margin-top: 0;
            background-color: #eee;
            border: 0;
            box-shadow: none;
            a{
              color: #282828;
              width: 100%;
              display: block;
              padding: 5px 25px;
            }
          }
        }
        a{
          color: #282828;
          text-transform: uppercase;
        }
        .dropdown-toggle:after {
          border-bottom-style: solid;
          border-bottom-width: 2px;
          border-right-style: solid;
          border-right-width: 2px;
          content: '';
          position: absolute;
          display: inline-block;
          top: 46%;
          right: 15px;
          transform: translateY(-46%) rotate(45deg);
          width: 8px;
          height: 8px;
        }
        .open{
          a{
              color: #282828;
          }
        }
        .dropdown-menu{
          position: static;
          float: none;
          width: auto;
          margin-top: 0;
          background-color: #eee;
          border: 0;
          box-shadow: none;
          a{
            color: #282828;
            width: 100%;
            display: block;
            padding: 5px 25px;
          }
        }

        .soc {
          width: auto;
        }
      }
      &__button{
        position: absolute;
        padding-bottom: 50px;
        width: 100%;
        display: flex;
        justify-content: center;
        bottom: 0;
        @media (min-device-width: 320px) and (max-device-width: 1024px) and (orientation: landscape) {
          position: relative;
        }
        .btn{
          padding: 17px 16px;
          width: 100%;
          max-width: 343px;
        }
      }

    }

    .header__burger span{
      background:  #282828;
    }
  }
  &__blocker{
    width: 100%;
    height: 100%;
    background: hwb(0deg 0% 100% / 40%);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2200;
    transform: translateX(-100%);
    transition: transform 0.5s ease-in-out;
    &.active {
      transform: translateX(0) !important;
    }
  }
}
